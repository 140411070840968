import React from "react"
import PropTypes from "prop-types"
import Img from "gatsby-image"
// @! Wrapper for an <a> tag that also tracks clicks through gtag
import { OutboundLink } from "gatsby-plugin-gtag"

const Headshot = ({ node, classes, styles, alignHeadshot }) => {

  const wrapper = alignHeadshot ? 'uk-grid' : '';
  const alignment = alignHeadshot ? 'uk-width-1-1 uk-width-2-5@m' : '';

  return (
    <div style={{ fontSize: "0.8rem", lineHeight: 1 }}>
      <div className={wrapper}>

        {node.position && <><strong>{node.position}{node.position?.trim() !== '' && ':'}</strong><br /><br /></>}

        <div className={alignment}>
          <div className={classes} style={styles}>
            <OutboundLink href={node.linkedin} target="_blank">
              <Img 
                fluid={node.headshot?.childImageSharp.fluid} 
                alt={node.name} 
                fadeIn={true} 
                imgStyle={{ borderRadius: 1000 }}
              />
            </OutboundLink>
          </div>
        </div>
        {/* <img src={node.headshot.childImageSharp.fluid.src} alt={node.name} /> */}
        
        <div className="uk-width-expand">
          <br />
          <div>
            <OutboundLink href={node.linkedin} target="_blank"><strong>{node.name}</strong></OutboundLink> <br />
            {node.jobtitle} <br />
            {node.company} {" "}
          </div>
          <Img 
            fluid={node.logo?.childImageSharp.fluid} 
            alt={node.company} 
            fadeIn={true}
            imgStyle={{
              filter: `contrast(50%) grayscale(100%) brightness(20)`,
              objectFit: `contain`,
              objectPosition: `center`,
              paddingTop: 5,
              paddingBottom: 5,
              maxHeight: 70,
            }}
          />
        </div>

      </div>

    </div>
  )

}

Headshot.propTypes = {
  node: PropTypes.object,
}

Headshot.defaultProps = {
  node: {},
}

export default Headshot
